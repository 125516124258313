var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "div-cus-mgmt-main" },
    [
      _c(
        "div",
        { staticClass: "div-cus-mgmt-main-title" },
        [
          _c("h1", [_vm._v("Customer Management")]),
          _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
            ? _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: { click: _vm.handleCreateCaOpenDialog },
                },
                [_vm._v("Add Customer")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "div-cus-mgmt-main-body" }, [
        _c("div", { staticClass: "div-cus-mgmt-main-left" }, [
          _c(
            "div",
            { staticClass: "div-cus-mgmt-main-left-tab" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleCpTabClick },
                  model: {
                    value: _vm.cpIsOnlineTabActive,
                    callback: function ($$v) {
                      _vm.cpIsOnlineTabActive = $$v
                    },
                    expression: "cpIsOnlineTabActive",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "Active", name: "ACTIVE" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "Inactive", name: "INACTIVE" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "Pending", name: "PENDING" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "div-cus-mgmt-main-left-search" },
            [
              _c(
                "el-input",
                {
                  staticClass: "round-input",
                  attrs: { placeholder: "Search Company Name" },
                  nativeOn: {
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleCpListSearch.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.cpSearchCompanyName,
                    callback: function ($$v) {
                      _vm.cpSearchCompanyName = $$v
                    },
                    expression: "cpSearchCompanyName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      type: "primary",
                      icon: "el-icon-search",
                    },
                    on: { click: _vm.handleCpListSearch },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.cpList.length > 0 && !_vm.companyListLoading
            ? _c(
                "div",
                { staticClass: "div-cus-mgmt-main-left-list" },
                _vm._l(_vm.cpList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      class: {
                        "div-active-cp-list-item":
                          index === _vm.cpListItemActive,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleCpListItemClick(index)
                        },
                      },
                    },
                    [
                      item.logoSrc
                        ? _c("img", {
                            attrs: {
                              height: "40px",
                              width: "40px",
                              src: item.logoSrc,
                              alt: "",
                            },
                          })
                        : _c(
                            "div",
                            { staticClass: "div-active-cp-list-item-no-logo" },
                            [
                              _c("el-avatar", { attrs: { size: 40 } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getAvatarName(
                                      item.counterpartyCompanyName
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                      _c("div", [
                        _c("p", [_vm._v(_vm._s(item.counterpartyCompanyName))]),
                        item.isOnlineCounterparty
                          ? _c("p", [_vm._v("Online")])
                          : _c("p", [_vm._v("Offline")]),
                      ]),
                      item.isFlagged
                        ? _c(
                            "div",
                            { staticClass: "div-cp-list-item-flagged" },
                            [_c("p", [_vm._v("Flagged")])]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm.companyListLoading
            ? _c("div", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.companyListLoading,
                    expression: "companyListLoading",
                  },
                ],
                staticClass: "div-company-list-loading",
              })
            : _c("div", { staticClass: "div-company-list-no-data" }, [
                _c("p", [_vm._v("No Data")]),
              ]),
        ]),
        _vm.caSelected && !_vm.cpDetailsLoading
          ? _c(
              "div",
              { staticClass: "div-cus-mgmt-main-right" },
              [
                _c("div", { staticClass: "div-cus-mgmt-main-right-top" }, [
                  _c("div", { staticClass: "div-cus-mgmt-main-company-info" }, [
                    _c(
                      "div",
                      {
                        staticClass: "div-cus-mgmt-main-company-info-title-btn",
                      },
                      [
                        _c("h3", [_vm._v("Company Overview")]),
                        _c(
                          "div",
                          { staticClass: "div-cus-mgmt-main-company-info-btn" },
                          [
                            _vm.cpIsOnlineTabActive === "PENDING" &&
                            _vm.caSelected.requestedBySysOrganizationId !==
                              _vm.sysOrganizationId &&
                            _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      round: "",
                                      type: "success",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.approveRejectCancelCaReq(
                                          "approve"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Approve ")]
                                )
                              : _vm._e(),
                            _vm.cpIsOnlineTabActive === "PENDING" &&
                            _vm.caSelected.requestedBySysOrganizationId !==
                              _vm.sysOrganizationId &&
                            _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      round: "",
                                      type: "danger",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.approveRejectCancelCaReq(
                                          "reject"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Reject ")]
                                )
                              : _vm._e(),
                            _vm.cpIsOnlineTabActive === "PENDING" &&
                            !_vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                              ? _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      round: "",
                                      type: "warning",
                                      size: "mini",
                                    },
                                  },
                                  [_vm._v(" Pending Approval ")]
                                )
                              : _vm._e(),
                            _vm.cpIsOnlineTabActive === "PENDING" &&
                            _vm.caSelected.requestedBySysOrganizationId ===
                              _vm.sysOrganizationId &&
                            _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      round: "",
                                      type: "danger",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.approveRejectCancelCaReq(
                                          "cancel"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel Request ")]
                                )
                              : _vm._e(),
                            !_vm.caSelected.isOnlineCounterparty &&
                            _vm.hasPermission(
                              _vm.$permissionFunctionCode.MANAGE_OFFLINE_USER
                            )
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      round: "",
                                      type: "primary",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.isEditOfflineUser = false
                                        _vm.dialogAddEditUser = true
                                      },
                                    },
                                  },
                                  [_vm._v("Add User")]
                                )
                              : _vm._e(),
                            _vm.caSelected != null &&
                            _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      round: "",
                                      size: "mini",
                                      outline: "",
                                    },
                                    on: {
                                      click: _vm.handleEditOfflineCompanyClick,
                                    },
                                  },
                                  [_vm._v("Edit")]
                                )
                              : _vm._e(),
                            _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                              ? _c(
                                  "el-dropdown",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { trigger: "click" },
                                    on: {
                                      command: _vm.handleDeactiveFlagCompany,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { padding: "5px 6px" } },
                                      [_c("i", { staticClass: "el-icon-more" })]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: { command: "DEFAULT_BANK" },
                                          },
                                          [_vm._v("Edit Default Bank")]
                                        ),
                                        _vm.caSelected.status === "INACTIVE"
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: { command: "ACTIVATE" },
                                              },
                                              [_vm._v("Activate Company")]
                                            )
                                          : _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: "DEACTIVATE",
                                                },
                                              },
                                              [_vm._v("Deactivate Company")]
                                            ),
                                        _vm.caSelected.isFlagged
                                          ? _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "UNFLAG" } },
                                              [_vm._v("Unflag Company")]
                                            )
                                          : _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "FLAG" } },
                                              [_vm._v("Flag Company")]
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "div-cus-mgmt-main-company-info-body" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "div-cus-mgmt-main-company-info-body-main",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "company-logo-name",
                                attrs: { div: "" },
                              },
                              [
                                _vm.caSelected && _vm.caSelected.logoSrc
                                  ? _c("img", {
                                      attrs: {
                                        height: "50px",
                                        width: "50px",
                                        src: _vm.caSelected.logoSrc,
                                        alt: "",
                                      },
                                    })
                                  : _c("el-avatar", { attrs: { size: 100 } }, [
                                      _c(
                                        "span",
                                        { staticClass: "company-info-avatar" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.cpCompany
                                                ? _vm.getAvatarName(
                                                    _vm.cpCompany.companyName
                                                  )
                                                : ""
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "div-cus-mgmt-main-company-info-body-details",
                              },
                              [
                                _c("div", [
                                  _c("h4", [_vm._v("Company Name:")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cpCompany &&
                                          _vm.cpCompany.companyName
                                          ? _vm.cpCompany.companyName
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("h4", [
                                    _vm._v("UEN/Business Incorporation No:"),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cpCompany &&
                                          _vm.cpCompany
                                            .businessIncorporationNumber
                                          ? _vm.cpCompany
                                              .businessIncorporationNumber
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("h4", [
                                    _vm._v("Country of Incorporation:"),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cpCompany &&
                                          _vm.cpCompany.incorporationCountry
                                          ? _vm.cpCompany.incorporationCountry
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("h4", [_vm._v("Incorporation Date:")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cpCompany &&
                                          _vm.cpCompany.incorporationDate
                                          ? _vm.cpCompany.incorporationDate
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("h4", [_vm._v("City/State:")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cpCompany &&
                                          _vm.cpCompany.cityOrState
                                          ? _vm.cpCompany.cityOrState
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("h4", [_vm._v("Street Name:")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cpCompany &&
                                          _vm.cpCompany.streetName
                                          ? _vm.cpCompany.streetName
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("h4", [_vm._v("Building Name:")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cpCompany &&
                                          _vm.cpCompany.buildingName
                                          ? _vm.cpCompany.buildingName
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("h4", [_vm._v("Unit No:")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cpCompany &&
                                          _vm.cpCompany.unitNumber
                                          ? _vm.cpCompany.unitNumber
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("h4", [_vm._v("Postal Code:")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cpCompany && _vm.cpCompany.postal
                                          ? _vm.cpCompany.postal
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "div-cus-mgmt-main-credit-info" }, [
                    _c(
                      "div",
                      {
                        staticClass: "div-cus-mgmt-main-credit-info-title-btn",
                      },
                      [
                        _c("h3", [_vm._v("Credit Limit")]),
                        _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                          ? _c(
                              "el-button",
                              {
                                attrs: { round: "", size: "mini", outline: "" },
                                on: { click: _vm.handleEditCreditInfoClick },
                              },
                              [_vm._v("Edit")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "div-cus-mgmt-main-credit-info-body" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "div-cus-mgmt-main-credit-info-body-main",
                          },
                          [
                            _c("div", [
                              _c("h4", [_vm._v("Credit Days:")]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.caCreditInfo.creditDays)),
                              ]),
                            ]),
                            _c("div", [
                              _c("h4", [_vm._v("Credit Limit:")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.caCreditInfo.creditLimitDisplay)
                                ),
                              ]),
                            ]),
                            _c("div", [
                              _c("h4", [_vm._v("Credit Used:")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.caCreditInfo.totalCreditUsedDisplay
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", [
                              _c("h4", [_vm._v("Balance Credit:")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.caCreditInfo.totalCreditBalanceDisplay
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.cpUsers,
                      height: "100%",
                      width: "100%",
                      "min-width": "800px",
                      "cell-style": { padding: "0", height: "70px" },
                      "header-cell-style": {
                        color: "#4C565C",
                        backgroundColor: "#F4F6FA",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "salutation", "min-width": "100px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v("Salutation")]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.salutation
                                        ? scope.row.salutation
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3590523568
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "firstName", "min-width": "150px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v("First Name")]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(scope.row.firstName)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3847881722
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "lastName", "min-width": "150px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v("Last Name")]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v(_vm._s(scope.row.lastName))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2145763898
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "mobile", "min-width": "100px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v("Contact No.")]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.mobile ? scope.row.mobile : "-"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2542729917
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "officePhoneNumber",
                        "min-width": "100px",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v("Office No.")]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.officePhoneNumber
                                        ? scope.row.officePhoneNumber
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1331816893
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "email", "min-width": "150px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v("Email Address")]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [_vm._v(_vm._s(scope.row.email))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1281908716
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "designation", "min-width": "100px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [_vm._v("Designation")]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(scope.row.designation)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1102615098
                      ),
                    }),
                    !_vm.caSelected.isOnlineCounterparty &&
                    _vm.hasPermission(
                      _vm.$permissionFunctionCode.MANAGE_OFFLINE_USER
                    )
                      ? _c("el-table-column", {
                          attrs: { "min-width": "50px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-button", {
                                      attrs: {
                                        round: "",
                                        size: "mini",
                                        icon: "el-icon-edit",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEditOfflineUserClick(
                                            scope.row
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            536585583
                          ),
                        })
                      : _vm._e(),
                    !_vm.caSelected.isOnlineCounterparty &&
                    _vm.hasPermission(
                      _vm.$permissionFunctionCode.MANAGE_OFFLINE_USER
                    )
                      ? _c("el-table-column", {
                          attrs: { "min-width": "50px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-button", {
                                      style: scope.row.isActive
                                        ? "background-color: #CC3333; color: #FFFFFF;"
                                        : "background-color: green; color: #FFFFFF;",
                                      attrs: {
                                        round: "",
                                        size: "mini",
                                        icon: scope.row.isActive
                                          ? "el-icon-delete"
                                          : "el-icon-refresh-left",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.activateDeactivateOfflineUser(
                                            scope.row
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3834804322
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.cpDetailsLoading
          ? _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cpDetailsLoading,
                  expression: "cpDetailsLoading",
                },
              ],
              staticClass: "div-cus-mgmt-main-right-loading",
            })
          : _c("div", { staticClass: "div-cus-mgmt-main-right-no-data" }, [
              _c("p", [_vm._v("No Data")]),
            ]),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-create-edit-ca",
          attrs: {
            width: "980px",
            title: `${
              _vm.isEditOfflineCompany
                ? "Edit Company"
                : "Create Credit Association"
            }`,
            visible: _vm.dialogCreateEditCa,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogCreateEditCa = $event
            },
            close: _vm.resetCaForm,
          },
        },
        [
          !_vm.isEditOfflineCompany
            ? _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleCreateCaTabClick },
                  model: {
                    value: _vm.createCaOnlineOffline,
                    callback: function ($$v) {
                      _vm.createCaOnlineOffline = $$v
                    },
                    expression: "createCaOnlineOffline",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "Online Company", name: "SYS_ONLINE_ORG" },
                  }),
                  _c("el-tab-pane", {
                    attrs: {
                      label: "Offline Company",
                      name: "SYS_OFFLINE_ORG",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form",
            { ref: "caForm", attrs: { model: _vm.caForm, rules: _vm.rules } },
            [
              _c(
                "div",
                { staticClass: "div-create-edit-ca-form-row" },
                [
                  _vm.createCaOnlineOffline !== "" ||
                  (_vm.isEditOfflineCompany &&
                    !_vm.caSelected?.isOnlineCounterparty)
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Company Name",
                              prop: "companyName",
                            },
                          },
                          [
                            _vm.createCaOnlineOffline === "SYS_OFFLINE_ORG" ||
                            _vm.isEditOfflineCompany
                              ? _c("el-input", {
                                  attrs: { rules: _vm.REQ_RULE },
                                  model: {
                                    value: _vm.caForm.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.caForm, "companyName", $$v)
                                    },
                                    expression: "caForm.companyName",
                                  },
                                })
                              : _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      "value-key": "id",
                                      rules: _vm.REQ_RULE,
                                    },
                                    on: { change: _vm.handleCompanyNameSelect },
                                    model: {
                                      value: _vm.caForm.company,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.caForm, "company", $$v)
                                      },
                                      expression: "caForm.company",
                                    },
                                  },
                                  _vm._l(
                                    _vm.createCompanyList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.companyName,
                                          value: item,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "UEN/Business Incorporation No.",
                              prop: "businessIncorporationNumber",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled:
                                  _vm.createCaOnlineOffline !==
                                    "SYS_OFFLINE_ORG" &&
                                  !_vm.isEditOfflineCompany,
                              },
                              model: {
                                value: _vm.caForm.businessIncorporationNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.caForm,
                                    "businessIncorporationNumber",
                                    $$v
                                  )
                                },
                                expression:
                                  "caForm.businessIncorporationNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.createCaOnlineOffline === "SYS_OFFLINE_ORG" ||
                        _vm.isEditOfflineCompany
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Company Abbreviation",
                                  prop: "abbreviation",
                                },
                              },
                              [
                                _c("el-input", {
                                  on: { input: _vm.handleChangeToCaps },
                                  model: {
                                    value: _vm.caForm.abbreviation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.caForm, "abbreviation", $$v)
                                    },
                                    expression: "caForm.abbreviation",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.createCaOnlineOffline === "SYS_OFFLINE_ORG" ||
                  (_vm.isEditOfflineCompany &&
                    !_vm.caSelected?.isOnlineCounterparty)
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Incorporation Date",
                              prop: "incorporationDate",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "date",
                                format: "dd-MM-yyyy",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                              },
                              model: {
                                value: _vm.caForm.incorporationDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.caForm, "incorporationDate", $$v)
                                },
                                expression: "caForm.incorporationDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Company Type", prop: "type" } },
                          [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: _vm.caForm.companyType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.caForm, "companyType", $$v)
                                  },
                                  expression: "caForm.companyType",
                                },
                              },
                              _vm._l(_vm.caOrgTypeList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "General Email", prop: "email" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.caForm.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.caForm, "email", $$v)
                                },
                                expression: "caForm.email",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Fax", prop: "fax" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.caForm.fax,
                                callback: function ($$v) {
                                  _vm.$set(_vm.caForm, "fax", $$v)
                                },
                                expression: "caForm.fax",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Country of Incorporation",
                              prop: "incorporationCountry",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.caForm.incorporationCountry,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.caForm,
                                    "incorporationCountry",
                                    $$v
                                  )
                                },
                                expression: "caForm.incorporationCountry",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Street Name", prop: "streetName" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.caForm.streetName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.caForm, "streetName", $$v)
                                },
                                expression: "caForm.streetName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Building Name",
                              prop: "buildingName",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.caForm.buildingName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.caForm, "buildingName", $$v)
                                },
                                expression: "caForm.buildingName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.createCaOnlineOffline === "SYS_OFFLINE_ORG" ||
                  (_vm.isEditOfflineCompany &&
                    !_vm.caSelected?.isOnlineCounterparty)
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Unit Number", prop: "unitNumber" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.caForm.unitNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.caForm, "unitNumber", $$v)
                                },
                                expression: "caForm.unitNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Postal/Zip Code", prop: "postal" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.caForm.postal,
                                callback: function ($$v) {
                                  _vm.$set(_vm.caForm, "postal", $$v)
                                },
                                expression: "caForm.postal",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.currentCompanySettings.pricingTierCheck
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Pricing Tier",
                              prop: "pricingTier",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.caForm.pricingTier,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.caForm, "pricingTier", $$v)
                                  },
                                  expression: "caForm.pricingTier",
                                },
                              },
                              _vm._l(_vm.tiers, function (tier) {
                                return _c("el-option", {
                                  key: tier.tierId,
                                  attrs: {
                                    label: tier.title,
                                    value: tier.tierId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Company Code (SAGE 300)",
                              prop: "victoryCompanyCode",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "Choose or input",
                                  filterable: "",
                                  "allow-create": "",
                                  remote: "",
                                  "remote-method":
                                    _vm.handleRemoteVictoryCompanyCode,
                                },
                                on: {
                                  "visible-change": function ($event) {
                                    return _vm.getVictoryCompanyCodeList($event)
                                  },
                                  change: _vm.handleChangeVictoryCompanyCode,
                                },
                                model: {
                                  value: _vm.caForm.victoryCompanyCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.caForm,
                                      "victoryCompanyCode",
                                      $$v
                                    )
                                  },
                                  expression: "caForm.victoryCompanyCode",
                                },
                              },
                              _vm._l(
                                _vm.victoryCompanyCodeList,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Group Code (SAGE 300)",
                              prop: "groupCode",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "Plese select" },
                                model: {
                                  value: _vm.caForm.groupCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.caForm, "groupCode", $$v)
                                  },
                                  expression: "caForm.groupCode",
                                },
                              },
                              _vm._l(
                                _vm.SAGE_GROUP_CODE_OPTIONS,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Account Set (SAGE 300)",
                              prop: "accountSet",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "Plese select",
                                  filterable: "",
                                },
                                on: {
                                  "visible-change": function ($event) {
                                    return _vm.getVictoryAccountSetList($event)
                                  },
                                },
                                model: {
                                  value: _vm.caForm.accountSet,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.caForm, "accountSet", $$v)
                                  },
                                  expression: "caForm.accountSet",
                                },
                              },
                              _vm._l(
                                _vm.victoryAccountSetList,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Billing Cycle (SAGE 300)",
                              prop: "billingCycle",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "Plese select",
                                  filterable: "",
                                },
                                on: {
                                  "visible-change": function ($event) {
                                    return _vm.getVictoryBillingCycleList(
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.caForm.billingCycle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.caForm, "billingCycle", $$v)
                                  },
                                  expression: "caForm.billingCycle",
                                },
                              },
                              _vm._l(
                                _vm.victoryBillingCycleList,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Terms (SAGE 300)", prop: "terms" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "Plese select",
                                  filterable: "",
                                },
                                on: {
                                  "visible-change": function ($event) {
                                    return _vm.getVictoryTermsList($event)
                                  },
                                },
                                model: {
                                  value: _vm.caForm.terms,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.caForm, "terms", $$v)
                                  },
                                  expression: "caForm.terms",
                                },
                              },
                              _vm._l(_vm.victoryTermsList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "el-dialog__footer" },
            [
              !_vm.isEditOfflineCompany
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.createCaOnlineOffline === "SYS_ONLINE_ORG"
                            ? _vm.requestOnlineCa()
                            : _vm.requestOfflineCa()
                        },
                      },
                    },
                    [_vm._v(" Create ")]
                  )
                : _vm.caSelected?.isOnlineCounterparty
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary" },
                      on: { click: _vm.updateOnlineCompany },
                    },
                    [_vm._v("Save")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary" },
                      on: { click: _vm.updateOfflineCompany },
                    },
                    [_vm._v("Save")]
                  ),
              _c(
                "el-button",
                {
                  attrs: { outline: "", round: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogCreateEditCa = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Edit Credit Information",
            visible: _vm.dialogEditCreditInfo,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditCreditInfo = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              [
                _c("p", { staticStyle: { "margin-top": "0" } }, [
                  _vm._v("Credit Days"),
                ]),
                _c("el-input-number", {
                  attrs: { controls: false, precision: 0, min: 0 },
                  on: { blur: _vm.handleCreditDaysBlur },
                  model: {
                    value: _vm.caCreditInfo.creditDays,
                    callback: function ($$v) {
                      _vm.$set(_vm.caCreditInfo, "creditDays", $$v)
                    },
                    expression: "caCreditInfo.creditDays",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("p", [_vm._v("Credit Limit")]),
                _c("el-input", {
                  attrs: {
                    type: "number",
                    controls: false,
                    precision: 0,
                    min: 0,
                  },
                  on: { blur: _vm.handleCreditLimitBlur },
                  model: {
                    value: _vm.caCreditInfo.creditLimit,
                    callback: function ($$v) {
                      _vm.$set(_vm.caCreditInfo, "creditLimit", $$v)
                    },
                    expression: "caCreditInfo.creditLimit",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("p", [_vm._v("Currency")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "Select",
                      disabled: _vm.isVictoryKioskCustomer,
                    },
                    model: {
                      value: _vm.caCreditInfo.creditCurrency,
                      callback: function ($$v) {
                        _vm.$set(_vm.caCreditInfo, "creditCurrency", $$v)
                      },
                      expression: "caCreditInfo.creditCurrency",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "SGD", value: "SGD" } }),
                    _c("el-option", { attrs: { label: "USD", value: "USD" } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "el-dialog__footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: { click: _vm.updateCreditInfo },
                },
                [_vm._v(" Save ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { outline: "", round: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogEditCreditInfo = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-create-edit-user",
          attrs: {
            title: _vm.isEditOfflineUser ? "Edit User" : "Add User",
            visible: _vm.dialogAddEditUser,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddEditUser = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "offlineUserForm",
              attrs: {
                model: _vm.offlineUserForm,
                rules: _vm.offlineUserFormRules,
              },
            },
            [
              _c(
                "div",
                { staticClass: "div-create-edit-user-form-dialog-top" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Salutation", prop: "salutation" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.offlineUserForm.salutation,
                            callback: function ($$v) {
                              _vm.$set(_vm.offlineUserForm, "salutation", $$v)
                            },
                            expression: "offlineUserForm.salutation",
                          },
                        },
                        _vm._l(_vm.salutationList, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "First Name", prop: "firstName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.offlineUserForm.firstName,
                          callback: function ($$v) {
                            _vm.$set(_vm.offlineUserForm, "firstName", $$v)
                          },
                          expression: "offlineUserForm.firstName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Last Name", prop: "lastName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.offlineUserForm.lastName,
                          callback: function ($$v) {
                            _vm.$set(_vm.offlineUserForm, "lastName", $$v)
                          },
                          expression: "offlineUserForm.lastName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "div-create-edit-user-form-dialog-mid" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Contact No.", prop: "mobile" } },
                    [
                      _c("phone-input", {
                        model: {
                          value: _vm.offlineUserForm.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.offlineUserForm, "mobile", $$v)
                          },
                          expression: "offlineUserForm.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Office No.", prop: "officePhoneNumber" },
                    },
                    [
                      _c("phone-input", {
                        model: {
                          value: _vm.offlineUserForm.officePhoneNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.offlineUserForm,
                              "officePhoneNumber",
                              $$v
                            )
                          },
                          expression: "offlineUserForm.officePhoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email Address", prop: "email" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.offlineUserForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.offlineUserForm, "email", $$v)
                          },
                          expression: "offlineUserForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Designation", prop: "designation" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.offlineUserForm.designation,
                          callback: function ($$v) {
                            _vm.$set(_vm.offlineUserForm, "designation", $$v)
                          },
                          expression: "offlineUserForm.designation",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "el-dialog__footer" },
            [
              !_vm.isEditOfflineUser
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary" },
                      on: { click: _vm.createOfflineUser },
                    },
                    [_vm._v(" Add ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary" },
                      on: { click: _vm.updateOfflineUser },
                    },
                    [_vm._v(" Save ")]
                  ),
              _c(
                "el-button",
                {
                  attrs: { outline: "", round: "" },
                  on: {
                    click: function ($event) {
                      _vm.resetOfflineUserForm()
                      _vm.dialogAddEditUser = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.caSelected
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogBank, title: "Default Bank" },
              on: {
                close: function ($event) {
                  _vm.dialogBank = false
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.bankLoading,
                      expression: "bankLoading",
                    },
                  ],
                },
                _vm._l(_vm.bankAccList, function (item, index) {
                  return _c("update-bank", {
                    key: index,
                    attrs: {
                      refKey: index === 0 ? "companyBank" : "chaserBank",
                      category: item.category,
                      sysOrganizationId:
                        _vm.caSelected.counterpartySysOrganizationId,
                      companyDefaultBank: _vm.companyDefaultBank,
                      chaserDefaultBank: _vm.chaserDefaultBank,
                      data: item.bankList,
                      isViewOnly: "",
                      hasRadio: "",
                    },
                    on: {
                      handleBankRadioSelectChange:
                        _vm.handleBankRadioSelectChange,
                    },
                  })
                }),
                1
              ),
              _c(
                "div",
                {
                  staticClass: "el-dialog__footer",
                  staticStyle: { "margin-right": "20px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { outline: "", round: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialogBank = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { round: "", type: "primary" },
                      on: { click: _vm.handleConfirmDefaultBank },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }